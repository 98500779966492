import { Box, Button } from "@mui/material";
import { FC } from "react";
import {
  CircleStencil,
  Cropper,
  CropperRef,
  ImageRestriction,
  FixedCropper,
  RectangleStencil,
} from "react-advanced-cropper";
import "react-advanced-cropper/dist/style.css";

interface IImageCropperProps {
  accepts?: string; // comma separated values ['image/png', 'image/jpeg', 'image/jpg']
  fieldName: string;
  originalFile: File | null;
  setOriginalFile: Function;
  setFieldValue: Function;
  setFieldTouched?: Function;
  inputRef: any;
  imageToCrop?: string;
  setImageToCrop: Function;
  isFixed?: boolean;
  stencilShape?: "circle" | "rectangle";
  fixedWidth?: number;
  fixedHeight?: number;
}

const ImageCropper: FC<IImageCropperProps> = ({
  setOriginalFile,
  setFieldValue,
  setFieldTouched,
  originalFile,
  fieldName,
  accepts,
  inputRef,
  imageToCrop,
  setImageToCrop,
  stencilShape = "circle",
  isFixed = false,
  fixedHeight,
  fixedWidth,
}) => {
  const handleChange = (newValue: File) => {
    setOriginalFile(newValue);
    const reader = new FileReader();
    reader.onload = (e: any) => {
      setImageToCrop(e.target.result);
    };
    reader.readAsDataURL(newValue);
  };

  const onChange = (cropper: CropperRef) => {
    setTimeout(() => {
      const canvas = cropper.getCanvas();

      if (canvas) {
        canvas.toBlob(
          (blob: any) => {
            const file = new File([blob], originalFile?.name || "image.png", {
              type: originalFile?.type || "image/png",
            });
            setFieldValue(fieldName, file);
            setFieldTouched && setFieldTouched(fieldName, true);
          },
          "image/png",
          1,
        );
      }
    });
  };

  return (
    <>
      <Button
        variant="contained"
        color="inherit"
        sx={{ ...styles.selectImageBtn }}
      >
        Select Image
        <input
          ref={inputRef}
          type="file"
          accept={accepts || "image/*"}
          onChange={(e: any) => handleChange(e.target.files[0])}
        />
      </Button>
      <Box
        sx={{
          width: "100%",
          border: "dotted 3px #e5eaf2",
          borderBottomRightRadius: "8px !important",
          borderBottomLeftRadius: "8px !important",
        }}
      >
        {isFixed ? (
          <FixedCropper
            src={imageToCrop}
            stencilProps={{
              handlers: false,
              lines: false,
              movable: false,
              resizable: false,
            }}
            stencilSize={{
              width: fixedWidth || 250,
              height: fixedHeight || 150,
            }}
            imageRestriction={ImageRestriction.stencil}
            onChange={onChange}
            style={{
              width: "100%",
              height: "400px",
              backgroundColor: "#f5f5f5",
            }}
          />
        ) : (
          <Cropper
            src={imageToCrop}
            stencilComponent={
              stencilShape === "circle" ? CircleStencil : RectangleStencil
            }
            imageRestriction={ImageRestriction.fitArea}
            style={{ width: "100%", height: "400px" }}
            onChange={onChange}
          />
        )}
      </Box>
    </>
  );
};

const styles = {
  selectImageBtn: {
    width: "100%",
    borderRadius: "0px !important",
    border: "dotted 3px inherit",
    borderTopRightRadius: "8px !important",
    borderTopLeftRadius: "8px !important",

    "& input": {
      width: "100%",
    },

    "& input[type=file]::file-selector-button": {
      display: "none",
    },

    // for webkit/blink browsers
    "& input[type=file]::-webkit-file-upload-button": {
      display: "none",
    },

    "& input[type=file]": {
      position: "absolute",
      backgroundColor: "red",
      cursor: "pointer",
      opacity: 0,
    },
  },
};

export default ImageCropper;
