import React from "react";
import "../../assets/css/CustomSpinner.css";
import ZimbarImage from "../../assets/images/zimba-z.png";

const CustomSpinner: React.FC = () => {
  return (
    <div className="loading-component">
      <div className="loader-container">
        <span className="loader-liquid">
          <img src={ZimbarImage} alt="Zimbar" className="loader-z" />
        </span>
        <div className="loading-dots">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
};

export default CustomSpinner;
