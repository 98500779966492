import instance from "../../../api";
export const ClientService = {
  async getClients(filters: Object) {
    return instance
      .get("client/", { params: filters })
      .then((response) => response.data);
  },

  async createClient(payload: Object) {
    return instance.post("client/", payload).then((response) => response.data);
  },

  async getClientDetails(id: string) {
    return instance.get(`client/${id}/`).then((response) => response.data);
  },

  async updateClient(payload: Object, id: string) {
    return instance
      .patch(`client/${id}/`, payload)
      .then((response) => response.data);
  },
  async addEmployer(payload: Object) {
    return instance
      .post(`employer/`, payload)
      .then((response) => response.data);
  },
  async updateEmployer(payload: Object, id: string) {
    return instance
      .put(`employer/${id}/`, payload)
      .then((response) => response.data);
  },

  async deleteClient(payload: Object, id: string) {
    return instance
      .put(`client/${id}/delete/`, payload)
      .then((response) => response.data);
  },

  async addComment(payload: Object) {
    return instance
      .post(`client/client-notes/`, payload)
      .then((response) => response.data);
  },

  async uploadClientSignture(payload: Object, id: string) {
    const { signature, ...form }: any = payload;

    if (signature) {
      const formData = new FormData();
      formData.append("sign", signature);

      return instance
        .patch(`user/${id}/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => response.data);
    }
  },

  async getClientAccounts(filters: Object) {
    return instance
      .get("client-accounts/", { params: filters })
      .then((response) => response.data);
  },

  async getBankAccounts(filters: Object) {
    return instance
      .get("account-bank/", {
        params: filters,
      })
      .then((response) => response.data);
  },

  async getCashAccounts(filters: Object) {
    return instance
      .get("account-cash/", {
        params: filters,
      })
      .then((response) => response.data);
  },

  async getMobileAccounts(filters: Object) {
    return instance
      .get("account-mobile/", {
        params: filters,
      })
      .then((response) => response.data);
  },

  async getClientAccountStatement(filters: Object, id: any) {
    return instance
      .get(`client-accounts/${id}/statement/`, {
        params: filters,
      })
      .then((response) => response.data);
  },

  async getClientStatement(filters: Object, id: any) {
    return instance
      .get(`client/${id}/statement/`, {
        params: filters,
      })
      .then((response) => response.data);
  },

  async enableDisableWithdrawAccount(payload: any, id: string) {
    return instance
      .put(`client-accounts/${id}/`, payload)
      .then((response) => response.data);
  },

  async deactivateClientAccount(payload: any, id: string) {
    return instance
      .patch(`client-accounts/${id}/`, payload)
      .then((response) => response.data);
  },

  async unfreezeClientAccount(payload: any, id: string) {
    return instance
      .patch(`client-accounts/${id}/unfreeze-amount/`, payload)
      .then((response) => response.data);
  },

  async convertToMember(payload: any, id: string) {
    return instance
      .put(`client/${id}/convert-to-member/`, payload)
      .then((response) => response.data);
  },

  async addClientAccount(payload: any) {
    return instance
      .post("client/add-account/", payload)
      .then((response) => response.data);
  },

  async updateClientAccount(payload: any, id: string) {
    return instance
      .patch(`client-accounts/${id}/`, payload)
      .then((response) => response.data);
  },

  async addClientBusiness(payload: any) {
    return instance
      .post("business/", payload)
      .then((response) => response.data);
  },

  async updateClientBusiness(payload: any, id: string) {
    return instance
      .patch(`business/${id}/`, payload)
      .then((response) => response.data);
  },

  async exportClients(filters: any) {
    return instance
      .get(`client/download_clients_xlsx/`, {
        params: filters,
      })
      .then((response) => response.data);
  },
  async SavingsAccountTransfer(payload: any) {
    return instance
      .post("client-accounts/transfer-savings/", payload)
      .then((response) => response.data);
  },

  async deleteClientBusiness(id: string) {
    return instance.delete(`business/${id}/`).then((response) => response.data);
  },

  async addClientBeneficiary(payload: any) {
    return instance
      .post("beneficiary/", payload)
      .then((response) => response.data);
  },

  async updateClientBeneficiary(payload: any, id: string) {
    return instance
      .patch(`beneficiary/${id}/`, payload)
      .then((response) => response.data);
  },

  async deleteClientBeneficiary(id: string) {
    return instance
      .delete(`beneficiary/${id}/`)
      .then((response) => response.data);
  },

  async getServiceIntergations(filters: Object) {
    return instance
      .get("service-access/", { params: filters })
      .then((response) => response.data);
  },

  async setUpService(payload: any) {
    return instance
      .post("service-access/", payload)
      .then((response) => response.data);
  },

  async exemptRecurringFees(payload: any, id: string) {
    return instance
      .patch(`client-accounts/${id}/`, payload)
      .then((response) => response.data);
  },

  async getClientActivityAudit(filters: Object) {
    return instance
      .get(`activity/`, { params: filters })
      .then((response) => response.data);
  },

  async restoreClient(payload: Object, id: string) {
    return instance
      .put(`client/${id}/restore-client/`, payload)
      .then((response) => response.data);
  },

  async getClientCreditHistory(filters: Object, id: any) {
    return instance
      .get(`client/${id}/credit-history/`, {
        params: filters,
      })
      .then((response) => response.data);
  },

  async addAccountSignatories(payload: any, headers: any) {
    return instance
      .post("client-accounts/signatories/", payload, {
        headers,
      })
      .then((response) => response.data);
  },

  async getAccountSignatories(filters: Object) {
    return instance
      .get(`client-accounts/signatories/`, { params: filters })
      .then((response) => response.data);
  },

  async updateAccountSignatories(payload: any, id: string) {
    return instance
      .patch(`client-accounts/signatories/${id}/`, payload)
      .then((response) => response.data);
  },

  async deleteAccountSignatories(id: string) {
    return instance
      .delete(`client-accounts/signatories/${id}/`)
      .then((response) => response.data);
  },

  async getWithdrawRequests(payload: any) {
    return instance
      .get(`client-accounts/initiated-withdraws/`, { params: payload })
      .then((response) => response.data);
  },

  async submitSignatoryApproval(id: string, payload: any) {
    return instance
      .patch(`client-accounts/signatory-approval/${id}/`, payload)
      .then((response) => response.data);
  },
};
