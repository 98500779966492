export const T_TYPE_DEPOSIT = "D";
export const T_TYPE_WITHDRAWAL = "W";

export const T_TYPES = [
  { name: "deposit", value: T_TYPE_DEPOSIT },
  { name: "withdrawal", value: T_TYPE_WITHDRAWAL },
];

export const DEPOSIT = "Deposit";
export const WITHDRAWAL = "Withdraw";

export const TRANSACTION_TYPES = [
  { name: "Deposit", value: DEPOSIT },
  { name: "Withdraw", value: WITHDRAWAL },
];

export const PAY_METHODS = [
  {
    name: "Cash",
    value: "cash",
  },
  {
    name: "Cheque/Bank Account",
    value: "cheque",
  },
  {
    name: "Mobile Money",
    value: "mobile",
  },
];
